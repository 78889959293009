export const IllustWifi = ({ width = '100%' }: JSX.IntrinsicElements['svg']) => (
  <svg width={width} viewBox='0 0 375 140' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_2932_30853)'>
      <path
        d='M218.805 73.242C220.932 71.1071 220.949 67.6149 218.605 65.7212C209.671 58.5063 198.553 54.5732 187.09 54.5732C175.628 54.5732 164.509 58.5063 155.575 65.7212C153.229 67.6177 153.248 71.1071 155.373 73.242C157.498 75.3768 160.93 75.3443 163.327 73.5129C170.169 68.3166 178.512 65.5051 187.089 65.5051C195.666 65.5051 204.008 68.3166 210.851 73.5129C213.25 75.3443 216.68 75.3795 218.805 73.242Z'
        fill='#DDDDDD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M232.153 47.4177C234.348 49.3142 234.337 52.6763 232.288 54.7326C230.244 56.7889 226.938 56.7672 224.719 54.9033C214.165 46.0402 200.847 41.1846 187.091 41.1846C173.336 41.1846 160.018 46.0402 149.464 54.9033C147.242 56.7672 143.939 56.8024 141.892 54.7326C139.846 52.6628 139.832 49.3142 142.03 47.4177C154.572 36.5944 170.558 30.6436 187.091 30.6436C203.625 30.6436 219.611 36.5944 232.153 47.4177ZM218.806 73.2418C220.934 71.1069 220.95 67.6148 218.607 65.721C209.672 58.5061 198.554 54.5731 187.091 54.5731C175.629 54.5731 164.51 58.5061 155.576 65.721C153.23 67.6175 153.249 71.1069 155.374 73.2418C157.499 75.3766 160.931 75.3441 163.328 73.5127C170.171 68.3164 178.513 65.505 187.09 65.505C195.667 65.505 204.01 68.3164 210.852 73.5127C213.252 75.3441 216.681 75.3793 218.806 73.2418ZM203.571 83.1738C206.071 84.7208 206.27 88.1398 204.334 90.3532C202.396 92.5693 199.052 92.7129 196.434 91.3881C193.597 89.9579 190.473 89.198 187.299 89.1665C184.126 89.1351 180.987 89.8329 178.123 91.2066C175.475 92.4772 172.137 92.2686 170.244 90.0146C168.351 87.7605 168.618 84.3469 171.147 82.8514C176.071 79.9407 181.691 78.4328 187.404 78.4896C193.116 78.5464 198.706 80.1658 203.571 83.1738ZM195.911 108.261C195.911 112.944 191.962 116.739 187.09 116.739C182.218 116.739 178.268 112.944 178.268 108.261C178.268 103.579 182.218 99.7835 187.09 99.7835C191.962 99.7835 195.911 103.579 195.911 108.261Z'
        fill='url(#paint0_linear_2932_30853)'
      />
      <path
        d='M232.288 54.7326C234.337 52.6763 234.348 49.3142 232.153 47.4177C219.611 36.5944 203.625 30.6436 187.091 30.6436C170.558 30.6436 154.572 36.5944 142.03 47.4177C139.832 49.3142 139.846 52.6628 141.892 54.7326C143.939 56.8024 147.242 56.7672 149.464 54.9033C160.018 46.0402 173.336 41.1846 187.091 41.1846C200.847 41.1846 214.165 46.0402 224.719 54.9033C226.938 56.7672 230.244 56.7889 232.288 54.7326Z'
        fill='url(#paint1_linear_2932_30853)'
      />
      <path
        d='M204.334 90.3529C206.27 88.1395 206.07 84.7205 203.571 83.1735C198.705 80.1655 193.116 78.5461 187.403 78.4893C181.691 78.4325 176.071 79.9405 171.147 82.8511C168.618 84.3466 168.351 87.7602 170.244 90.0143C172.136 92.2684 175.474 92.477 178.122 91.2063C180.986 89.8326 184.125 89.1348 187.299 89.1663C190.472 89.1977 193.597 89.9576 196.433 91.3879C199.051 92.7127 202.395 92.5691 204.334 90.3529Z'
        fill='url(#paint2_linear_2932_30853)'
      />
      <path
        opacity='0.55'
        d='M218.805 73.2415C220.932 71.1066 220.949 67.6145 218.605 65.7207C209.671 58.5058 198.553 54.5728 187.09 54.5728C175.628 54.5728 164.509 58.5058 155.575 65.7207C153.229 67.6172 153.248 71.1066 155.373 73.2415C157.498 75.3763 160.93 75.3438 163.327 73.5124C170.169 68.3161 178.512 65.5047 187.089 65.5047C195.666 65.5047 204.008 68.3161 210.851 73.5124C213.25 75.3438 216.68 75.379 218.805 73.2415Z'
        fill='url(#paint3_linear_2932_30853)'
        fillOpacity='0.65'
      />
      <path
        d='M229.712 60.9834C236.955 60.9834 242.827 55.1117 242.827 47.8687C242.827 40.6256 236.955 34.7539 229.712 34.7539C222.469 34.7539 216.598 40.6256 216.598 47.8687C216.598 55.1117 222.469 60.9834 229.712 60.9834Z'
        fill='#666666'
      />
      <path
        d='M235.129 51.7428L231.245 47.8686L235.129 43.9943C235.335 43.7896 235.45 43.512 235.45 43.2224C235.45 42.9329 235.335 42.6553 235.129 42.4506C235.028 42.3492 234.907 42.2688 234.774 42.2139C234.642 42.1591 234.499 42.1309 234.355 42.1309C234.065 42.1309 233.787 42.2459 233.582 42.4506L229.697 46.3248L225.812 42.4506C225.604 42.2603 225.33 42.1575 225.048 42.1635C224.766 42.1695 224.496 42.2839 224.297 42.4828C224.097 42.6818 223.981 42.95 223.975 43.2315C223.968 43.5131 224.071 43.7863 224.261 43.9943L228.146 47.8686L224.261 51.7428C224.071 51.9508 223.968 52.2241 223.975 52.5056C223.981 52.7872 224.097 53.0553 224.297 53.2543C224.496 53.4532 224.766 53.5676 225.048 53.5737C225.33 53.5797 225.604 53.4768 225.812 53.2865L229.697 49.4123L233.582 53.2865C233.787 53.4913 234.065 53.6063 234.355 53.6063C234.646 53.6063 234.924 53.4913 235.129 53.2865C235.335 53.0818 235.45 52.8042 235.45 52.5147C235.45 52.2252 235.335 51.9475 235.129 51.7428Z'
        fill='white'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_2932_30853'
        x1='187.091'
        y1='30.6558'
        x2='187.091'
        y2='202.823'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C8C8C8' />
        <stop offset='0.71875' stopColor='#DCDCDC' />
        <stop offset='1' stopColor='#DDDDDD' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_2932_30853'
        x1='235.771'
        y1='54.1331'
        x2='140.419'
        y2='20.2669'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#BDBDBD' />
        <stop offset='1' stopColor='#DDDDDD' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_2932_30853'
        x1='226.844'
        y1='100.328'
        x2='169.088'
        y2='78.1142'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.492883' stopColor='#CCCCCC' />
        <stop offset='1' stopColor='#DDDDDD' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_2932_30853'
        x1='214.548'
        y1='69.1801'
        x2='157.952'
        y2='60.8352'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#CCCCCC' />
        <stop offset='1' stopColor='#B7B7B7' stopOpacity='0' />
      </linearGradient>
      <clipPath id='clip0_2932_30853'>
        <rect width='112.295' height='100' fill='white' transform='translate(131.352 20)' />
      </clipPath>
    </defs>
  </svg>
);
