import { UxBrowsingType } from './graphql/fragment_types.2';

/**
 * DETAIL_BROWSER 방어코드 처리를 위한 함수
 * - DETAIL_BROWSER 케이스에서 쿼리파라미터에 DETAIL_BROWSER가 존재하지 않으면 INTERNAL_BROWSER로 치환한다.
 * @param browsing_type
 * @param url
 * @returns browsing_type
 */
export function getAdjustedBrowsingType(browsing_type: keyof typeof UxBrowsingType, url?: string | null) {
  const isDetailBrowser = browsing_type === 'DETAIL_BROWSER';
  const hasQueryBrowsingType = !!url?.includes('DETAIL_BROWSER');

  if (isDetailBrowser && !hasQueryBrowsingType) return 'INTERNAL_BROWSER';

  return browsing_type;
}
